<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .el-table th {
    border: unset;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import MonitorHeader from "./monitor-header.vue";
  import {
    getServer
  } from "@/api/monitor/server";

  /**
   * 系统监控--在线用户
   */
  export default {
    components: {
      Layout,
      MonitorHeader


    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统监控",
            active: true
          }
        ],
        // 加载层信息
        // loading: [],
        // 服务器信息
        server: {
          sysFiles: []
        }
      };
    },
    created() {
      this.getList();
      // this.openLoading();
    },
    methods: {
      /** 查询服务器信息 */
      getList() {
        getServer().then(response => {
          this.server = response.data;
          // this.loading.close();
        });
      },
      // // 打开加载层
      // openLoading() {
      //   this.loading = this.$loading({
      //     lock: true,
      //     text: "拼命读取中",
      //     spinner: "el-icon-loading",
      //     background: "rgba(0, 0, 0, 0.7)"
      //   });
      // },
    
    }




  };
</script>

<template>
  <Layout>
    <MonitorHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">

        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="mt-2">
              <el-row :gutter="20">
                <el-col :span="12" class="mb-3">
                  <el-card>
                    <div slot="header"><span>CPU</span>
                     <!-- <el-button style="float: right; padding: 3px 0" type="text" plain icon="el-icon-arrow-down" >
                      </el-button> -->
                      <!-- <el-button style="float: right; padding: 3px 0" type="text" plain icon="el-icon-arrow-up">
                      </el-button> -->
                    </div>
                    <div class="el-table">
                      <table class="w-100 table-hover table">
                        <thead>
                          <tr>
                            <th>
                              <div>属性</div>
                            </th>
                            <th>
                              <div>值</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>核心数</div>
                            </td>
                            <td>
                              <div v-if="server.cpu">{{ server.cpu.cpuNum }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>用户使用率</div>
                            </td>
                            <td>
                              <div v-if="server.cpu">{{ server.cpu.used }}%</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>系统使用率</div>
                            </td>
                            <td>
                              <div v-if="server.cpu">{{ server.cpu.sys }}%</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>当前空闲率</div>
                            </td>
                            <td>
                              <div v-if="server.cpu">{{ server.cpu.free }}%</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="12" class="mb-3">
                  <el-card>
                    <div slot="header"><span>内存</span></div>
                    <div class="el-table">
                      <table class="w-100 table-hover table">
                        <thead>
                          <tr>
                            <th>
                              <div>属性</div>
                            </th>
                            <th>
                              <div>内存</div>
                            </th>
                            <th>
                              <div>JVM</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>总内存</div>
                            </td>
                            <td>
                              <div v-if="server.mem">{{ server.mem.total }}G</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.total }}M</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>已用内存</div>
                            </td>
                            <td>
                              <div v-if="server.mem">{{ server.mem.used}}G</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.used}}M</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>剩余内存</div>
                            </td>
                            <td>
                              <div v-if="server.mem">{{ server.mem.free }}G</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.free }}M</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>使用率</div>
                            </td>
                            <td>
                              <div v-if="server.mem" :class="{'text-danger': server.mem.usage > 80}">
                                {{ server.mem.usage }}%
                              </div>
                            </td>
                            <td>
                              <div v-if="server.jvm" :class="{'text-danger': server.jvm.usage > 80}">
                                {{ server.jvm.usage }}%
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="mb-3">
                  <el-card>
                    <div slot="header">
                      <span>服务器信息</span>
                    </div>
                    <div class="el-table ">
                      <table class="w-100 table-hover table">
                        <tbody>
                          <tr>
                            <td>
                              <div>服务器名称</div>
                            </td>
                            <td>
                              <div v-if="server.sys">{{ server.sys.computerName }}</div>
                            </td>
                            <td>
                              <div>操作系统</div>
                            </td>
                            <td>
                              <div v-if="server.sys">{{ server.sys.osName }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>服务器IP</div>
                            </td>
                            <td>
                              <div v-if="server.sys">{{ server.sys.computerIp }}</div>
                            </td>
                            <td>
                              <div>系统架构</div>
                            </td>
                            <td>
                              <div v-if="server.sys">{{ server.sys.osArch }}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="mb-3">
                  <el-card>
                    <div slot="header">
                      <span>Java虚拟机信息</span>
                    </div>
                    <div class="el-table">
                      <table class="w-100 table-hover table">
                        <tbody>
                          <tr>
                            <td>
                              <div>Java名称</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.name }}</div>
                            </td>
                            <td>
                              <div>Java版本</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.version }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>启动时间</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.startTime }}</div>
                            </td>
                            <td>
                              <div>运行时长</div>
                            </td>
                            <td>
                              <div v-if="server.jvm">{{ server.jvm.runTime }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1">
                              <div>安装路径</div>
                            </td>
                            <td colspan="3">
                              <div v-if="server.jvm">{{ server.jvm.home }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1">
                              <div>项目路径</div>
                            </td>
                            <td colspan="3">
                              <div v-if="server.sys">{{ server.sys.userDir }}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="mb-3">
                  <el-card>
                    <div slot="header">
                      <span>磁盘状态</span>
                    </div>
                    <div class="el-table ">
                      <table class="w-100 table-hover table">
                        <thead>
                          <tr>
                            <th>
                              <div>盘符路径</div>
                            </th>
                            <th>
                              <div>文件系统</div>
                            </th>
                            <th>
                              <div>盘符类型</div>
                            </th>
                            <th>
                              <div>总大小</div>
                            </th>
                            <th>
                              <div>可用大小</div>
                            </th>
                            <th>
                              <div>已用大小</div>
                            </th>
                            <th>
                              <div>已用百分比</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="server.sysFiles">
                          <tr v-for="sysFile in server.sysFiles" :key="sysFile.dirName">
                            <td>
                              <div>{{ sysFile.dirName }}</div>
                            </td>
                            <td>
                              <div>{{ sysFile.sysTypeName }}</div>
                            </td>
                            <td>
                              <div>{{ sysFile.typeName }}</div>
                            </td>
                            <td>
                              <div>{{ sysFile.total }}</div>
                            </td>
                            <td>
                              <div>{{ sysFile.free }}</div>
                            </td>
                            <td>
                              <div>{{ sysFile.used }}</div>
                            </td>
                            <td>
                              <div :class="{'text-danger': sysFile.usage > 80}">{{ sysFile.usage }}%</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
