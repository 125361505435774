<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<script>
  export default {
    components: {},
    data() {
      return {
        isActive: 1,
        pathArr: [{
            index: 1,
            url: "/admin/onlineUser",
            name: "在线用户",
          },
          {
            index: 2,
            url: "/admin/",
            name: "定时任务",
          },
          {
            index: 3,
            url: "/admin/",
            name: "数据监控",
          },
          {
            index: 4,
            url: "/admin/server",
            name: "服务监控",
          },
          {
            index: 5,
            url: "/admin/cache",
            name: "缓存监控",
          },

        ]
      }
    },

    props: {
      items: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    mounted() {
      this.isActive = window.location.pathname

    }
  }
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box flexList justify-content-between">
        <div class="flexList col-4 p-0">
          <a :href="obj.url" class="col-sm-3 p-0 mr-3" v-for="obj in pathArr" :key="obj.index">
            <div class="base-header-box " :class="{active:isActive==obj.url}">{{obj.name}}</div>
          </a>
        </div>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
